const helpers = {
    /**
     * In case of a "storm of events", this executes once every $threshold
     *
     * @param fn
     * @param threshold
     * @param scope
     * @returns {Function}
     */
    throttle: function (fn, threshold, scope) {
      threshold || (threshold = 250);
      var last, deferTimer;

      return function () {
        var context = scope || this;
        var now = +new Date(),
          args = arguments;

        if (last && now < last + threshold) {
          // Hold on to it
          clearTimeout(deferTimer);
          deferTimer = setTimeout(function () {
            last = now;
            fn.apply(context, args);
          }, threshold);
        } else {
          last = now;
          fn.apply(context, args);
        }
      };
    },
  };
  module.exports = helpers;  