import $ from 'jquery';
import 'what-input';

// Foundation JS relies on a global variable. In ES6, all imports are hoisted
// to the top of the file so if we used `import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
window.$ = $;
//require('foundation-sites');

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
import './lib/foundation-explicit-pieces';
import './lib/jquery.magnific-popup.min';
import helpers from './lib/helpers'


$(document).foundation();

/* Sprungmarken-Animation ------------------------------------------- */
$('a[href^="#"]').on('click',function(e) {
    if(typeof $(this).data('smooth-scroll') === 'undefined' && $(this).attr('href') !== '#' && $(this).attr('role') !== 'tab' && $(this).parents('#mainnav').length <= 0){
      var target = this.hash;
      var $target = $(target);
      $('html, body').stop().animate({
        'scrollTop': $target.offset().top - $('#mainheader').height()
      }, 800, 'swing');
      return false;
    }
});


/* YouTube --------------------------------------------------------- */
$('.youtube button').on('click', function(){
  var blankImg = $(this).attr('data-blankimg'),
      iframe = document.createElement( "iframe" );

  iframe.setAttribute( "allowfullscreen", "" );
  iframe.setAttribute( "width", $(this).attr('data-width'));
  iframe.setAttribute( "height", $(this).attr('data-height'));
  iframe.setAttribute( "src", $(this).attr('data-src').split('?')[0]);
  iframe.setAttribute( "data-cookiefirst-category", "functional");

  $(this).parent('.youtube').after( iframe ).after('<img src="'+blankImg+'" width="1920" height="1080" aria-hidden="true" />');
  $(this).parent('.youtube').remove();

  return false;
});


/* Gallery -------------------------------------------------------------*/
if($('.lightbox-img').length > 0 || $('.mod_gallery').length > 0){
  $.extend(true, $.magnificPopup.defaults, {
      tClose: 'Schließen (Esc)', // Alt text on close button
      tLoading: 'Laden...', // Text that is displayed during loading. Can contain %curr% and %total% keys
      gallery: {
        tPrev: 'Zurück', // Alt text on left arrow
        tNext: 'Weiter', // Alt text on right arrow
        tCounter: '%curr% von %total%' // Markup for "1 of 7" counter
      },
      image: {
        tError: '<a href="%url%">Das Bild</a> konnte nicht geladen werden.' // Error message when image could not be loaded
      },
      ajax: {
        tError: '<a href="%url%">Der Inhalt</a> konnte nicht geladen werden.' // Error message when ajax request failed
      }
  });
}
if($('.lightbox-img').length > 0){
  $('.lightbox-img').magnificPopup({
      type: 'image'
  });
}
if($('.mod_gallery').length > 0){
  $('.mod_gallery').each(function() { // the containers for all your galleries
    $(this).magnificPopup({
        delegate: 'a', // the selector for gallery item
        type: 'image',
        gallery: {
          enabled:true
        }
    });
});
}


/* Mobile nav ------------------------------------------------------- */
function toggleMobileNav(){
  $('.offcanvas').fadeToggle();
  return false;
}
function hideMobileNav(){
  if($('.drilldown').length > 0){
    $('.offcanvas').hide();
  } else {
    $('.offcanvas').show();
  }
  $('.offcanvas').css('visibility', 'visible');
}

// initialization
$(window).on('load', hideMobileNav);
$(window).on('resize', 
  helpers.throttle(
    function(){
      $('.offcanvas').show().css('visibility', 'hidden');
      $('.offcanvas').find('.invisible').removeClass('invisible');
      setTimeout(hideMobileNav, 100);
    }
  )
);

// click button
$('#mobile-nav-btn').on('click', toggleMobileNav);
$('#close-mobile-nav-btn').on('click', toggleMobileNav);


/* Desktop Nav Scolled ------------------------------------------------*/
function setScrolledNav(){
  if($(window).width() > 1023){
    if($(window).scrollTop() > 0){
      $('body').addClass('scrolled');
    } else {
      $('body').removeClass('scrolled');
    }
  } else {
    $('body').removeClass('scrolled');
  }
}

$(window).on('scroll', setScrolledNav);
$(window).on('load', setScrolledNav);


/* Hero ----------------------------------------------------------- */
function setHeroImage(mod, images){
  if($(window).width() >= 1200){
    mod.css('background-image', 'url('+images[0]+')');
  } else if($(window).width() >= 1024){
    mod.css('background-image', 'url('+images[1]+')');
  } else if($(window).width() >= 640){
    mod.css('background-image', 'url('+images[2]+')');
  } else {
    mod.css('background-image', 'url('+images[3]+')');
  }
}

function setHeroModulesImages(){
  $('.mod_hero--size-large').each(function(){
    if(!$(this).data('images')){ return; }

    var images = $(this).data('images').split(',');

    if(images.length === 4){
      setHeroImage($(this), images);
    }
  })
}

$(window).on('load', setHeroModulesImages);
$(window).on('resize', setHeroModulesImages);

//Video Pause
$(document).on('click', '.pause-video-button', function(){
  var icon = $(this).children('.fa-solid'),
      video = $(this).parents('.mod_hero--size-large').find('video').get(0);

  if(icon.hasClass('fa-pause')){
    video.pause();
    icon.removeClass('fa-pause').addClass('fa-play');
  } else {
    video.play();
    icon.removeClass('fa-play').addClass('fa-pause');
  }
  return false;
}) ;


/* Counter --------------------------------------------------------- */
const speed = 300;
const formatter = new Intl.NumberFormat('de-DE');
const counters = document.querySelectorAll('.mod_numbers [data-target]');
const options = {rootMargin: "100px"};

const numbersObserver = new IntersectionObserver(function(entries, observer){
	entries.forEach((entry) => {
    const updateCount = () => {
      const counter = entry.target;
      const target = parseInt(counter.getAttribute('data-target'));
      const count = parseInt(counter.innerText.replace('.', ''));
      const increment = (target > speed) ? Math.trunc(target / speed): 1;
  
      if (count < target) {
        counter.innerText = formatter.format(count + increment);
        setTimeout(updateCount, 1);
      } else {
        counter.innerText = formatter.format(target);
      }
    };
    if(entry.isIntersecting) {
			updateCount();
		} 
  });
}, options);

counters.forEach(function(counter) {
	numbersObserver.observe(counter);
});